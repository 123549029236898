<template>
  <div class="createPost-container">
    <el-form ref="postForm" :model="postForm" :rules="rules" class="form-container">
      <sticky :class-name="'sub-navbar '">
        <editor-button :loading="loading" action="save" @click="handleSave" />
        <editor-button :loading="loading" action="saveClose" @click="saveAndClose" />
        <editor-button action="cancel" @click="close" />
      </sticky>
      <div class="createPost-main-container">
        <el-form-item :label="$t('common.name')" prop="name">
          <el-input
            v-model="postForm.name"
            :placeholder="$t('common.name')"
            name="name"
            type="text"
            auto-complete="on"
            maxlength="64"
          />
          <input-errors :errors="errors.name" />
        </el-form-item>

        <el-form-item :label="$t('login.code')" prop="code">
          <el-input
            v-model="postForm.code"
            :placeholder="$t('login.code')"
            name="code"
            type="text"
            auto-complete="on"
            maxlength="255"
          />
          <input-errors :errors="errors.code" />
        </el-form-item>

        <el-form-item :label="$t('common.reference')" prop="reference">
          <el-input
            v-model="postForm.reference"
            :placeholder="$t('common.reference')"
            name="reference"
            type="text"
            auto-complete="on"
            maxlength="8"
          />
        </el-form-item>

        <el-form-item :label="$t('common.email')" prop="email">
          <el-input
            v-model="postForm.email"
            :placeholder="$t('common.email')"
            name="email"
            type="text"
            auto-complete="on"
            maxlength="64"
          />
          <input-errors :errors="errors.email" />
        </el-form-item>

        <el-form-item :label="$t('common.description')" prop="description">
          <el-input
            v-model="postForm.description"
            :placeholder="$t('common.description')"
            name="description"
            type="textarea"
            auto-complete="on"
            maxlength="255"
          />
          <input-errors :errors="errors.description" />
        </el-form-item>

        <el-form-item :label="$t('user.avatar')" prop="description">
          <el-input
            v-model="postForm.avatar"
            :placeholder="$t('user.avatarInfo')"
            name="description"
            type="text"
            auto-complete="on"
          />
          <input-errors :errors="errors.avatar" />
        </el-form-item>

        <el-form-item :label="$t('navbar.language')" prop="language">
          <el-select v-model="postForm.languageID" :placeholder="$t('navbar.language')">
            <el-option v-for="lang in languages" :key="lang.id" :label="lang.description" :value="lang.id"> </el-option>
          </el-select>
        </el-form-item>

        <!--  todo make component for new password and repeat password and build a validator for that component -->
        <el-form-item :label="$t('user.oldPassword')" prop="oldPassword">
          <el-input
            v-model="postForm.oldPassword"
            type="password"
            :placeholder="$t('user.oldPasswordInfo')"
            name="newPassword"
          />
          <input-errors :errors="errors.oldpassword" />
        </el-form-item>
        <el-form-item :label="$t('user.newPassword')" prop="newPassword" :show-message="false">
          <el-input
            v-model="postForm.newPassword"
            type="password"
            :placeholder="$t('user.newPasswordInfo')"
            name="newPassword"
          />
          <input-errors :errors="errors.newpassword" />
        </el-form-item>
        <div v-if="newPasswordValidator.active" class="password-validation-output">
          <el-alert
            v-if="!newPasswordValidator.length"
            :title="$t('validation.error.length', { count: passwordMinimumLength })"
            type="error"
            :closable="false"
          />
          <el-alert
            v-if="!newPasswordValidator.uppercase"
            :title="$t('validation.error.uppercase')"
            type="error"
            :closable="false"
          />
          <el-alert
            v-if="!newPasswordValidator.lowercase"
            :title="$t('validation.error.lowercase')"
            type="error"
            :closable="false"
          />
          <el-alert
            v-if="!newPasswordValidator.digit"
            :title="$t('validation.error.digit')"
            type="error"
            :closable="false"
          />
          <el-alert
            v-if="!newPasswordValidator.alphanumeric"
            :title="$t('validation.error.alphanumeric')"
            type="error"
            :closable="false"
          />
          <el-alert
            v-if="!newPasswordValidator.isFromDatabase"
            :title="$t('validation.error.isFromDatabase')"
            type="error"
            :closable="false"
          />
        </div>
        <el-form-item :label="$t('user.repeatPassword')" prop="repeatPassword">
          <el-input
            v-model="postForm.repeatPassword"
            type="password"
            :placeholder="$t('user.repeatPasswordInfo')"
            name="repeatPassword"
          />
          <input-errors :errors="errors.repeatpassword" />
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import Sticky from '@/components/Sticky'; // Sticky header-component
import EditorButton from '@/components/crud/EditorButton';
import { closeCurrentTag } from '@/utils/tags';
import InputErrors from '@/components/DSE/InputErrors';
import { handleDatabaseRequestError } from '@/utils/error';
import { focusFirstInputInEl } from '@/utils/index';
import {
  validateEmail,
  validateUpperCase,
  validateLowerCase,
  validateDigit,
  validateNonAlphanumeric
} from '@/utils/validate';

const defaultForm = {
  avatar: '',
  code: '',
  description: '',
  email: '',
  name: '',
  newPassword: '',
  oldPassword: '',
  reference: '',
  repeatPassword: '',
  languageID: null
};

export default {
  name: 'Settings',
  components: {
    Sticky,
    EditorButton,
    InputErrors
  },
  data() {
    const validEmail = (rule, value, callback) => {
      if (value && !validateEmail(value)) {
        callback(new Error(this.$i18n.t('validation.email')));
      } else {
        callback();
      }
    };
    const validateRequire = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$i18n.t('validation.required')));
      } else {
        callback();
      }
    };
    const validateOldPassword = (rule, value, callback) => {
      if (this.postForm.newPassword !== '' && value === '') {
        callback(new Error(this.$i18n.t('validation.oldPasswordRequired')));
      } else {
        callback();
      }
    };
    const validateNewPassword = (rule, value, callback) => {
      if (value !== '') {
        const isValid = this.passwordCompliesWithPolicy(value);
        if (Object.values(isValid).some(e => !e)) {
          callback(new Error());
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    const validateRepeatPassword = (rule, value, callback) => {
      if (this.postForm.newPassword !== value) {
        callback(new Error(this.$i18n.t('validation.repeatPasswordMismatch')));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      postForm: Object.assign({}, defaultForm),
      errors: [],
      rules: {
        code: [{ validator: validateRequire }],
        email: [{ validator: validEmail }, { validator: validateRequire }],
        name: [{ validator: validateRequire }],
        newPassword: [{ validator: validateNewPassword }],
        oldPassword: [{ validator: validateOldPassword }],
        repeatPassword: [{ validator: validateRepeatPassword }]
      }
    };
  },
  computed: {
    newPasswordValidator: function() {
      return {
        ...this.passwordCompliesWithPolicy(this.postForm.newPassword),
        active: this.postForm.newPassword !== ''
      };
    },
    passwordMinimumLength: function() {
      return this.$store.state.authentication.passwordPolicy.minimumLength;
    },
    validationErrorInputPrefix() {
      return this.$store.getters['validationErrorInputPrefix'];
    },
    languages() {
      return this.$store.getters['language/loadedItems'];
    }
  },
  mounted() {
    focusFirstInputInEl(this.$el);
  },
  created() {
    this.postForm.name = this.$store.state.authentication.name;
    this.postForm.code = this.$store.state.authentication.code;
    this.postForm.email = this.$store.state.authentication.email;
    this.postForm.description = this.$store.state.authentication.description;
    this.postForm.avatar = this.$store.state.authentication.avatar;
    this.postForm.reference = this.$store.state.authentication.reference;
    const languages = this.$store.getters['language/loadedItems'];
    const language = languages.find(e => e.code === this.$store.state.authentication.preferredLanguage);
    this.postForm.languageID = language && language.id;
    this.$store.dispatch('getPasswordPolicy');
    window.addEventListener('beforeunload', this.beforeUnload, false);
    this.$store.dispatch('notify/disableErrorCodePrefix', this.validationErrorInputPrefix);
  },
  destroyed() {
    window.removeEventListener('beforeunload', this.beforeUnload);
    this.$store.dispatch('notify/enableErrorCodePrefix', this.validationErrorInputPrefix);
  },
  methods: {
    beforeUnload(e) {
      if (this.formHasChanged()) {
        // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
        e.preventDefault();
        e.returnValue = '';
        return '';
      }
    },
    passwordCompliesWithPolicy(value) {
      const passwordPolicy = this.$store.state.authentication.passwordPolicy;
      return {
        alphanumeric: !passwordPolicy.requireNonAlphanumeric || validateNonAlphanumeric(value),
        digit: !passwordPolicy.requireDigit || validateDigit(value),
        isFromDatabase: passwordPolicy.isFromDatabase,
        length: value.length >= passwordPolicy.minimumLength,
        lowercase: !passwordPolicy.requireLowercase || validateLowerCase(value),
        uppercase: !passwordPolicy.requireUppercase || validateUpperCase(value)
      };
    },
    saveData() {
      this.loading = true;
      this.$store
        .dispatch('updateLoggedInUser', this.postForm)
        .then(() => {
          this.$store.dispatch('notify/success', { message: this.$i18n.t('common.data_updated') });
        })
        .catch(error => {
          this.errors = handleDatabaseRequestError(this, error);
        })
        .finally(() => {
          this.loading = false;
        });

      const languages = this.$store.getters['language/loadedItems'];
      const language = languages.find(e => e.id === this.postForm.languageID);
      if (language) {
        this.$store.dispatch('setPreferredLanguageOfCurrentUser', language.code);
      }
      if (this.postForm.newPassword) {
        this.loading = true;
        this.$store
          .dispatch('changePasswordOfLoggedInUser', this.postForm)
          .then(() => {
            this.$store.dispatch('notify/success', { message: this.$i18n.t('user.passwordChanged') });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    submitForm() {
      this.$refs.postForm.validate(valid => {
        if (valid) {
          this.saveData();
        } else {
          return false;
        }
      });
    },
    formHasChanged() {
      return !!(
        this.postForm.name !== this.$store.state.authentication.name ||
        this.postForm.code !== this.$store.state.authentication.code ||
        this.postForm.email !== this.$store.state.authentication.email ||
        this.postForm.description !== this.$store.state.authentication.description ||
        this.postForm.avatar !== this.$store.state.authentication.avatar
      );
    },
    async handleSave() {
      try {
        await this.$refs.postForm.validate();
      } catch (err) {
        this.$store.dispatch('notify/formValidationFailed');
        return false;
      }

      try {
        await this.saveData();
        return true;
      } catch (error) {
        this.errorMessage = error && error.response && error.response.data;
        this.errorMessage = this.errorMessage || error.message || error || this.$i18n.t('common.data_save_error');
        this.$store.dispatch('notify/error', { message: this.$i18n.t('common.data_save_error') });
        this.actionLoading = false;
        return false;
      }
    },
    async saveAndClose() {
      if (await this.handleSave()) {
        closeCurrentTag(this);
      }
    },
    async close() {
      try {
        if (this.formHasChanged()) {
          await this.$store.dispatch('notify/closeUnsavedConfirm');
        }
        closeCurrentTag(this);
      } catch (err) {
        // do nothing and stay on page
      }
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import '~@/styles/mixin.scss';

.createPost-container {
  position: relative;

  .createPost-main-container {
    padding: 40px 45px 20px 50px;

    .el-select {
      width: 100%;
    }

    .postInfo-container {
      position: relative;
      @include clearfix;
      margin-bottom: 10px;

      .postInfo-container-item {
        float: left;
      }
    }
  }
}
</style>
